export default {
    panel: {
        gap: "8px",
        first: {
            border: {
                width: "0",
            },
        },
        last: {
            border: {
                width: "0",
            },
        },
        border: {
            width: "0",
        },
    },
    item: {
        color: "{primary.gray.lighten}",
        focus: {
            color: "{primary.gray.default}",
        },
    },
}
